import { Flex, IconButton, Text } from '@chakra-ui/react'
import { type FC } from 'react'
import { MdCopyAll } from 'react-icons/md'

type Props = { created: Date; token: string; uid: string }

const WebhookInfo: FC<Props> = ({ created, token }) => {
  const link = `https://api.capturi.ai/importer/webhook?token=${token}`
  return (
    <>
      <Flex justifyContent="space-between">
        <Text ml="1" fontSize="lg">
          Webhook url
        </Text>
        <IconButton
          onClick={() => navigator.clipboard.writeText(link)}
          icon={<MdCopyAll />}
          variant="link"
          colorScheme="primary"
          aria-label={'copy link '}
        />
      </Flex>
      <Text
        title={created.toLocaleString()}
        mt="4"
        mb="6"
        userSelect="all"
        bg="gray.100"
        p="3"
        fontFamily="monospace"
        borderRadius="md"
      >
        {link}
      </Text>
    </>
  )
}

export default WebhookInfo
