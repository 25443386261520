import { useModal } from '@capturi/use-modal'
import {
  Box,
  Button,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import {
  TableWrapper,
  TableWrapperHeader,
} from 'pages/Organization/components/TableWrapper'
import React from 'react'
import { useWebHooks } from '../Api/Webhooks/useWebhooks'
import WebhookLogModal from '../Components/Modals/WebhookLogModal'
import { WebhookModal } from '../Components/Modals/WebhookModal'
import { WebhookTableRow } from '../Components/Rows/WebhookTableRow'

export const Webhooks: React.FC = () => {
  const [openWebhookModal] = useModal(WebhookModal)
  const [openWebhookLogModal] = useModal(WebhookLogModal)
  const { data, isLoading } = useWebHooks()

  const hasData = !!data?.length

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box width="full" overflowX="auto">
          <TableWrapper>
            <TableWrapperHeader
              title={i18n._('Webhooks')}
              tooltip={t`An automated HTTP request triggered by an event`}
            >
              <Button onClick={() => openWebhookLogModal()}>
                <Trans>Webhook log</Trans>
              </Button>
              <Button
                colorScheme="primary"
                onClick={() => openWebhookModal({ webhookUid: undefined })}
              >
                <Trans>Create webhook</Trans>
              </Button>
            </TableWrapperHeader>
            <Table variant="bordered">
              {hasData && (
                <Thead>
                  <Tr>
                    <Th>
                      <Trans>Name</Trans>
                    </Th>
                    <Th>
                      <Trans>Event name</Trans>
                    </Th>
                    <Th>
                      <Trans>Method</Trans>
                    </Th>
                    <Th>
                      <Trans>Active</Trans>
                    </Th>
                    <Th w="5%" />
                  </Tr>
                </Thead>
              )}
              <Tbody>
                {!hasData ? (
                  <Tr w="100%">
                    <Td w="100%">
                      <Text fontSize="md" fontWeight="bold" textAlign="center">
                        <Trans>No content. Start by creating a webhook</Trans>
                      </Text>
                    </Td>
                  </Tr>
                ) : (
                  <>
                    {data?.map((webhook) => (
                      <WebhookTableRow key={webhook.uid} {...webhook} />
                    ))}
                  </>
                )}
              </Tbody>
            </Table>
          </TableWrapper>
        </Box>
      )}
    </Box>
  )
}
