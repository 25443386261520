import { useOrganization } from '@capturi/stores'
import { Button, useToast } from '@capturi/ui-components'
import {
  Box,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Icon,
  Stack,
  Switch,
  Text,
  VStack,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, select, t } from '@lingui/macro'
import React from 'react'
import { IoIosWarning } from 'react-icons/io'
import { MdClearAll } from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import { useArchivedConversationDeletionPolicy } from 'pages/Archive/api/useArchivedConversations'
import {
  DeletionPolicyFormData,
  useDeletionPolicyForm,
  useSystemSettings,
  useUpdateSystemSettings,
} from 'pages/Organization/hooks/useUpdateSystemSettings'
import {
  AuditLogEntry,
  SystemSettings,
  SystemSettingsModel,
} from 'pages/Organization/types'
import DaysNumberInput from './DaysNumberInput'

const numberToTimeSpan = (n: number): string => `${n}.00:00:00`

const timeSpanToNumber = (timeSpan: string | null): number | undefined =>
  timeSpan ? Number(timeSpan.split('.')[0]) : undefined

const AuditLog: React.FC<{ entry: AuditLogEntry }> = ({ entry }) => {
  return (
    <Text color="gray.600" fontSize="sm">
      {`${entry.message} (${i18n.date(entry.date, {
        timeStyle: 'short',
        dateStyle: 'medium',
      })})`}
    </Text>
  )
}
const AuditLogList: React.FC<{ auditLog: AuditLogEntry[] | undefined }> = ({
  auditLog,
}) => {
  return (
    <>
      {auditLog?.reverse().map((entry, i) => (
        <AuditLog entry={entry} key={i} />
      ))}
    </>
  )
}

export const selectDeletionPolicy = (data: SystemSettings) => {
  return (
    data && {
      audioSnippetTimeToLive: timeSpanToNumber(
        data.deletionStrategy.audioSnippetTimeToLive,
      ),
      conversationSoftTimeToLive: timeSpanToNumber(
        data.deletionStrategy.conversationSoftTimeToLive,
      ),
      conversationHardTimeToLive: timeSpanToNumber(
        data.deletionStrategy.conversationHardTimeToLive,
      ),
      caseTimeToLive: timeSpanToNumber(data.deletionStrategy.caseTimeToLive),
      anonymizeAsrData: data.deletionStrategy.anonymizeAsrData,
      effectiveBy: data.deletionStrategy.effectiveBy,
      auditLog: data.deletionStrategy.auditLog,
      created: data.deletionStrategy.created,
    }
  )
}

function formDataToUpdateDeletionPolicyModel(
  formData: DeletionPolicyFormData,
): SystemSettingsModel['deletionStrategy'] {
  const model: SystemSettingsModel['deletionStrategy'] = {}
  if (formData.conversationSoftTimeToLive !== undefined) {
    model.conversationSoftTimeToLive = numberToTimeSpan(
      formData.conversationSoftTimeToLive,
    )
  }
  if (formData.conversationHardTimeToLive !== undefined) {
    model.conversationHardTimeToLive = numberToTimeSpan(
      formData.conversationHardTimeToLive,
    )
  }
  if (formData.audioSnippetTimeToLive !== undefined) {
    model.audioSnippetTimeToLive = numberToTimeSpan(
      formData.audioSnippetTimeToLive,
    )
  }
  if (formData.caseTimeToLive !== undefined) {
    model.caseTimeToLive = numberToTimeSpan(formData.caseTimeToLive)
  }
  if (formData.anonymizeAsrData !== undefined) {
    model.anonymizeAsrData = formData.anonymizeAsrData
  }
  return model
}

const ValidationError: React.FC<{ message: string | undefined }> = ({
  message,
}) => {
  return (
    <Text color="red.500" fontSize="xs" fontStyle="italic">
      {message}
    </Text>
  )
}

const DeletionPolicy: React.FC = () => {
  const theme = useTheme()
  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    formData,
    isValid,
    validationErrors,
    hasChanges,
    updateForm,
    resetForm,
  } = useDeletionPolicyForm()
  const { mutate: updateSystemSettings, isPending } = useUpdateSystemSettings()
  const { data } = useSystemSettings({ select: selectDeletionPolicy })
  const { organizationType } = useOrganization()
  const { isArchiveOrg, useEmailChannelAsDefault } = useFeatureFlags()
  const { data: archivedConversationDeletionPolicy } =
    useArchivedConversationDeletionPolicy()

  const saveDeletionStrategy = React.useCallback(() => {
    if (!hasChanges) return

    updateSystemSettings(
      { deletionStrategy: formDataToUpdateDeletionPolicyModel(formData) },
      {
        onSuccess: () => {
          toast({
            description: t`Deletion policy has been updated and will come into effect in 72 hours`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Deletion policy has not been updated`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
  }, [formData, hasChanges, updateSystemSettings, toast])
  return (
    <Box>
      {!!data && data.effectiveBy > new Date() && (
        <Card overflow="hidden" borderRadius="md" shadow="none" mb={4}>
          <CardBody
            background="warningLight"
            borderLeftWidth="4px"
            borderLeftColor="warning"
            p={4}
          >
            <Flex justifyContent="start" alignItems="center" gap={2}>
              <Icon as={IoIosWarning} boxSize="24px" color="warningDark" />
              <Text fontSize="md">
                <Trans>
                  Deletion policy has been updated, and will come into effect
                  starting on the{' '}
                  {i18n.date(data.effectiveBy, {
                    timeStyle: 'short',
                    dateStyle: 'short',
                  })}
                  .
                </Trans>
              </Text>
            </Flex>
          </CardBody>
        </Card>
      )}

      <Grid
        gap={4}
        mt={4}
        templateColumns={{
          base: '1fr',
          md: '1fr 1px 1fr',
          lg: '1fr 1px 1fr 1fr',
        }}
      >
        <GridItem>
          <Stack spacing={4}>
            {!useEmailChannelAsDefault && (
              <Text fontWeight="medium" fontSize="lg">
                <Trans>Conversations</Trans>
              </Text>
            )}

            <Stack spacing={6}>
              {!useEmailChannelAsDefault && (
                <>
                  <Box>
                    <Text fontWeight="medium" fontSize="md">
                      <Trans>Audio</Trans>
                    </Text>
                    <Text color="gray.600" fontWeight="regular" fontSize="xs">
                      {select(organizationType, {
                        public:
                          'Citizen phone numbers will also be anonymized when the conversation audio is deleted',
                        other:
                          'Customer phone numbers will also be anonymized when the conversation audio is deleted',
                      })}
                    </Text>
                    <FormControl mt="2" whiteSpace="nowrap" alignItems="center">
                      <Flex alignItems="center" justifyContent="flex-start">
                        <Text m="0" fontSize="sm" fontWeight="normal">
                          <Trans>Keep conversation audio for</Trans>
                        </Text>
                        <DaysNumberInput
                          isDisabled={isPending || !data}
                          onChange={(number) =>
                            updateForm({
                              conversationSoftTimeToLive: number,
                            })
                          }
                          value={formData?.conversationSoftTimeToLive}
                        />
                      </Flex>
                      <ValidationError
                        message={validationErrors?.conversationSoftTimeToLive}
                      />
                    </FormControl>
                    <FormControl whiteSpace="nowrap" alignItems="center" mt={1}>
                      <Flex alignItems="start">
                        <Switch
                          key="enableAnonymizeAsrData"
                          isChecked={formData?.anonymizeAsrData}
                          onChange={() =>
                            updateForm({
                              anonymizeAsrData: !formData?.anonymizeAsrData,
                            })
                          }
                          isDisabled={isPending || !data}
                          size="sm"
                          mr="4"
                        />
                        <VStack align="start" spacing={0}>
                          <Text fontSize="sm" fontWeight="normal">
                            <Trans>
                              Anonymize statistics when deleting audio
                            </Trans>
                          </Text>
                          <Text fontSize="xs" fontWeight="normal" mt={0}>
                            <Trans>
                              Remove known numbers and names from transcriptions
                            </Trans>
                          </Text>
                        </VStack>
                      </Flex>
                    </FormControl>
                  </Box>
                  <Box>
                    <Text fontWeight="medium" fontSize="md">
                      <Trans>Statistics</Trans>
                    </Text>
                    <FormControl mt="2" whiteSpace="nowrap" alignItems="center">
                      <Flex alignItems="center" justifyContent="flex-start">
                        <Text m="0" fontSize="sm" fontWeight="normal">
                          <Trans>Keep conversation statistics for</Trans>
                        </Text>
                        <DaysNumberInput
                          isDisabled={isPending || !data}
                          onChange={(number) =>
                            updateForm({
                              conversationHardTimeToLive: number,
                            })
                          }
                          value={formData?.conversationHardTimeToLive}
                        />
                      </Flex>
                      <ValidationError
                        message={validationErrors?.conversationHardTimeToLive}
                      />
                    </FormControl>
                  </Box>
                </>
              )}
              {useEmailChannelAsDefault && (
                <Box>
                  <Text fontWeight="medium" fontSize="md">
                    <Trans>Text</Trans>
                  </Text>
                  <Text color="gray.600" fontWeight="regular" fontSize="xs">
                    <Trans>
                      Statistics related to tickets are also deleted when
                      tickets are deleted.
                    </Trans>
                  </Text>
                  <FormControl mt="2" whiteSpace="nowrap" alignItems="center">
                    <Flex alignItems="center" justifyContent="flex-start">
                      <HStack>
                        <Text m="0" fontSize="sm" fontWeight="normal">
                          <Trans>Save a ticket for</Trans>
                        </Text>
                        <DaysNumberInput
                          isDisabled={isPending || !data}
                          onChange={(number) =>
                            updateForm({
                              caseTimeToLive: number,
                            })
                          }
                          value={formData?.caseTimeToLive}
                        />
                        <Text m="0" fontSize="sm" fontWeight="normal">
                          <Trans> from creation</Trans>
                        </Text>
                      </HStack>
                    </Flex>
                  </FormControl>
                </Box>
              )}
              {isArchiveOrg &&
                archivedConversationDeletionPolicy?.timeToLive && (
                  <Box>
                    <Text fontWeight="medium" fontSize="md">
                      <Trans>Archive</Trans>
                    </Text>
                    <Text color="gray.600" fontWeight="regular" fontSize="xs">
                      <Trans>
                        Files are permanently deleted{' '}
                        {i18n.number(
                          Number.parseInt(
                            archivedConversationDeletionPolicy?.timeToLive,
                          ),
                          {
                            style: 'unit',
                            unit: 'day',
                          },
                        )}{' '}
                        after creation. Contact your customer succes manager if
                        you want to change this.
                      </Trans>
                    </Text>
                  </Box>
                )}
            </Stack>
          </Stack>
        </GridItem>
        <GridItem
          borderRightColor="gray.200"
          borderRightWidth="1px"
          display={{ base: 'none', md: 'block' }}
        />
        {!useEmailChannelAsDefault && (
          <GridItem>
            <Stack spacing={4}>
              <Text fontWeight="medium" fontSize="lg">
                <Trans>Library</Trans>
              </Text>
              <Box>
                <Text fontWeight="medium" fontSize="md">
                  <Trans>Audio</Trans>
                </Text>
                <FormControl mt="2" whiteSpace="nowrap" alignItems="center">
                  <Flex alignItems="center" justifyContent="flex-start">
                    <Text m="0" fontSize="sm" fontWeight="normal">
                      <Trans>Keep library snippets for</Trans>
                    </Text>
                    <DaysNumberInput
                      isDisabled={isPending || !data}
                      onChange={(number) =>
                        updateForm({ audioSnippetTimeToLive: number })
                      }
                      value={formData?.audioSnippetTimeToLive}
                    />
                  </Flex>
                  <ValidationError
                    message={validationErrors?.audioSnippetTimeToLive}
                  />
                </FormControl>
              </Box>
            </Stack>
          </GridItem>
        )}
        <GridItem colSpan={{ base: 1, md: 3, lg: 1 }}>
          <Card
            background="gray.50"
            border={`${theme.colors.gray[200]} 1px solid`}
            shadow="none"
          >
            <CardBody>
              {data?.auditLog
                .slice(-2)
                .reverse()
                .map((log, i) => (
                  <AuditLog entry={log} key={i} />
                ))}

              <Button
                background="gray.100"
                leftIcon={<MdClearAll />}
                mt={2}
                onClick={onOpen}
              >
                <Trans>See Log</Trans>
              </Button>

              <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement="right"
                size="md"
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader
                    borderBottom="1px"
                    borderBottomColor="gray.200"
                    lineHeight={1.4}
                    display="flex"
                    alignItems="center"
                    pr={10}
                  >
                    <Text fontSize="1rem">
                      <Trans>Log</Trans>
                    </Text>
                  </DrawerHeader>
                  <DrawerBody p={6}>
                    <Box background="gray.50" p={4}>
                      <AuditLogList auditLog={data?.auditLog} />
                    </Box>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
      <Flex justifyContent="end" gap={2} mt={4}>
        <Button
          isDisabled={!hasChanges}
          isLoading={isPending}
          onClick={() => resetForm()}
        >
          <Trans>Cancel</Trans>
        </Button>

        <Button
          isDisabled={!(hasChanges && isValid)}
          isLoading={isPending}
          onClick={saveDeletionStrategy}
          primary
        >
          {data && data.created.getTime() >= 0 ? (
            <Trans>Update deletion policy</Trans>
          ) : (
            <Trans>Create deletion policy</Trans>
          )}
        </Button>
      </Flex>
    </Box>
  )
}

export default DeletionPolicy
