import { downloadBlob } from '@capturi/request'
import { i18n } from '@lingui/core'
import { useMutation } from '@tanstack/react-query'

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const useDownloadArchiveReport = () =>
  useMutation({
    mutationFn: async (data: {
      fromInclusive: Date
      toInclusive: Date
    }) => {
      const from = i18n.date(data.fromInclusive, dateFormatOptions)
      const to = i18n.date(data.toInclusive, dateFormatOptions)
      await downloadBlob(
        'archive/v1/conversations/report',
        `ArchiveReport-${from}-${to}.csv`,
        {
          method: 'post',
          json: {
            fromInclusive: data.fromInclusive,
            toInclusive: data.toInclusive,
          },
        },
      )
    },
  })
