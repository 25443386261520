import { Box } from '@chakra-ui/react'
import React from 'react'

import { useFeatureFlags } from '@capturi/feature-flags'
import ApiTokens from './ApiTokens/Index'
import { Webhooks } from './Webhooks/Index'
import Widgets from './Widgets/Index'

export const ApiWebhooks: React.FC = () => {
  const { isArchiveOrg } = useFeatureFlags()
  return (
    <Box pb={4}>
      {!isArchiveOrg && (
        <Box mb={4}>
          <Webhooks />
        </Box>
      )}
      <Box mb={4}>
        <ApiTokens />
      </Box>
      {!isArchiveOrg && (
        <Box mb={4}>
          <Widgets />
        </Box>
      )}
    </Box>
  )
}
