import { PhoneFilterValues } from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import {
  FilterMenuPlacementProvider,
  FilterPeriodProvider,
  PhoneFilter,
  toDuration,
  useFilterDefinitions,
  useFilterPeriodContext,
} from '@capturi/filters'
import { ErrorBoundaryWithFallbackComponent } from '@capturi/react-utils'
import {
  Box,
  FormControl,
  FormLabel,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC, Suspense } from 'react'

const FilterSkeleton = () => (
  <Stack spacing={2}>
    <Skeleton height="32px" />
    <Skeleton height="32px" />
  </Stack>
)

const FilterFallback = () => (
  <Box mt={4}>
    <Text color="danger" fontWeight="medium">
      <Trans>
        Unfortunately, filters could not be loaded. Try refreshing the page
      </Trans>
    </Text>
  </Box>
)

export const WebhookFilter: FC<{
  filter: Partial<PhoneFilterValues>
  setFilter?: (state: Partial<PhoneFilterValues>) => void
  readonly?: boolean
}> = ({ filter, setFilter }) => {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const { periodDef } = useFilterPeriodContext()
  const duration = toDuration({
    min: filter.duration?.min,
    max: filter.duration?.max,
  })

  return (
    <ErrorBoundaryWithFallbackComponent FallbackComponent={FilterFallback}>
      <Suspense fallback={<FilterSkeleton />}>
        <FilterPeriodProvider defaultPeriod={periodDef}>
          <FilterMenuPlacementProvider value="bottom-start">
            <FormControl mt={4}>
              <FormLabel>
                <Trans>Filter</Trans>
              </FormLabel>
              <PhoneFilter
                key={JSON.stringify(filter)}
                filterDefinitions={filterDefinitions}
                disabledChannels={['email']}
                state={{
                  channel: 'phone',
                  values: {
                    ...filter,
                    duration: duration,
                  },
                }}
                onStateChange={(filter) => {
                  setFilter?.(filter.values)
                }}
              />
            </FormControl>
          </FilterMenuPlacementProvider>
        </FilterPeriodProvider>
      </Suspense>
    </ErrorBoundaryWithFallbackComponent>
  )
}
