import { TextConfigV2 } from '@capturi/integrations'
import { useModal } from '@capturi/use-modal'
import { Button, Stack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import AddImporterModal from './AddImporterModal'
import { BoostAi } from './boostAi'
import { GenesysChat } from './genesysChat'

const Importer: React.FC = () => {
  const [openAdd] = useModal(AddImporterModal)
  return (
    <div>
      <Stack spacing="10">
        <GenesysChat />
        <BoostAi />
        <TextConfigV2 />
      </Stack>

      <Button onClick={openAdd} mt="8">
        <Trans>Add Integration</Trans>
      </Button>
    </div>
  )
}

export default Importer
