export type IntegrationsV2 =
  | 'liveAgent'
  | 'cognigy-chat'
  | 'oresundsbron-mail'
  | 'genesys'
  | 'zendesk'
  | 'puzzel-chat'
  | 'etrack1'
  | 'demo-text'
  | 'focalscope-sftp'
  | 'dixa-text'
  | 'puzzel-case-mangement'
  | 'kindly-chat'
  | 'cognigy-genesys-chat'
  | 'dsb_kcit'

export type IntegrationV2 = {
  name: string
  key: IntegrationsV2
  color: string
  configV2: true
  url: string
}

export const textIntegrationsV2: IntegrationV2[] = [
  {
    color: '#59195D',
    key: 'liveAgent',
    name: 'LiveAgent',
    configV2: true,
    url: 'liveagent',
  },
  {
    color: '#59195D',
    key: 'cognigy-chat',
    name: 'cognigy-chat',
    configV2: true,
    url: 'cognigy-chat',
  },
  {
    configV2: true,
    color: '#2980c9',
    url: 'oresundsbron-mail',
    key: 'oresundsbron-mail',
    name: 'OB mail',
  },
  {
    color: '#FF4F1F',
    key: 'genesys',
    name: 'Genesys',
    url: 'genesys',
    configV2: true,
  },
  {
    color: '#FF4F1F',
    key: 'genesys',
    name: 'Genesys',
    url: 'genesys',
    configV2: true,
  },
  {
    color: '#16140d',
    key: 'zendesk',
    name: 'Zendesk',
    configV2: true,
    url: 'zendesk',
  },
  {
    color: '#ff375b',
    key: 'puzzel-chat',
    name: 'Puzzel Chat',
    configV2: true,
    url: 'puzzel-chat',
  },
  {
    color: '#649a3d',
    key: 'etrack1',
    name: 'eTrack1',
    configV2: true,
    url: 'etrack1',
  },
  {
    color: '#cc3b59',
    key: 'demo-text',
    name: 'Demo text',
    configV2: true,
    url: 'demo-text',
  },
  {
    color: '#339793',
    key: 'focalscope-sftp',
    name: 'Focalscope Sftp',
    configV2: true,
    url: 'focalscope-sftp',
  },
  {
    color: '#5644d8',
    key: 'dixa-text',
    name: 'Dixa Text',
    configV2: true,
    url: 'dixa-text',
  },
  {
    color: '#623875',
    key: 'puzzel-case-mangement',
    name: 'Puzzel Case Mangement',
    configV2: true,
    url: 'puzzel-case-mangement',
  },
  {
    color: '#1bd300',
    key: 'kindly-chat',
    name: 'Kindly Chat',
    configV2: true,
    url: 'kindly-chat',
  },
  {
    color: '#59195D',
    key: 'cognigy-genesys-chat',
    name: 'Cognigy genesys chat',
    configV2: true,
    url: 'cognigy-genesys-chat',
  },
  {
    color: '#B41730',
    key: 'dsb_kcit',
    name: 'DSB',
    configV2: true,
    url: 'dsb_kcit',
  },
]
