import { useFilterPeriodContext } from '@capturi/filters'
import { DateRangePicker } from '@capturi/ui-components'
import { Box, Button, Text, VStack, useToast } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useState } from 'react'
import { useDownloadArchiveReport } from './useDownloadArchiveReport'

export const ArchiveReport: FC = () => {
  const { period } = useFilterPeriodContext()
  const [date, setDate] = useState<{ from: Date; to: Date }>({
    from: period.from,
    to: period.to,
  })
  const { mutate: generateArchiveReport, isPending } =
    useDownloadArchiveReport()
  const toast = useToast()

  const handleDownload = () => {
    generateArchiveReport(
      {
        fromInclusive: period.from,
        toInclusive: period.to,
      },
      {
        onSuccess: () => {
          toast({
            title: t`Archive report generated`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Error generating archive report`,
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }
  return (
    <Box>
      <Text fontWeight="medium" fontSize="lg">
        <Trans>Download archive report</Trans>
      </Text>
      <Text fontSize="sm" color="textMuted" mb={6}>
        <Trans>
          Below you can download a CSV file with all the conversation data in
          the selected period.
        </Trans>
      </Text>
      <VStack align="flex-start">
        <Text>
          <Trans>Select period and download</Trans>
        </Text>
        <DateRangePicker
          value={date}
          showInputs
          placeholderFrom={t`From`}
          placeholderTo={t`To`}
          onSelectDateRange={setDate}
          disabled={[{ after: new Date() }]}
        />
        <Button
          isLoading={isPending}
          onClick={handleDownload}
          m="2"
          mt="4"
          colorScheme="primary"
        >
          <Trans>Download CSV</Trans>
        </Button>
      </VStack>
    </Box>
  )
}
