import { useModal } from '@capturi/use-modal'
import { HStack, Icon, IconButton, Td, Tr, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import { FC } from 'react'
import {
  MdCloud,
  MdCloudOff,
  MdCopyAll,
  MdDelete,
  MdEdit,
} from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'
import { Webhook } from '../../Api/Webhooks/models/Webhooks'
import { useDeleteWebhook } from '../../Api/Webhooks/useWebhooks'
import { WebhookModal } from '../Modals/WebhookModal'

const renderEvent = (event: string) => {
  switch (event) {
    case 'ConversationSummaryUpdated':
      return <Trans>Conversation summary updated</Trans>
    case 'ConversationProcessed':
      return <Trans>Conversation processed</Trans>
    case 'LiveSummaryCreated':
      return <Trans>Live summary created (Agent Co-pilot)</Trans>
  }
}

export const WebhookTableRow: FC<Webhook> = ({ ...props }) => {
  const [openWebhookModal] = useModal(WebhookModal)
  const { mutate: deleteWebhook } = useDeleteWebhook()
  const [, copy] = useCopyToClipboard()
  const toast = useToast()

  const handleDeleteWebhook = (): void => {
    deleteWebhook(props.uid, {
      onSuccess: () => {
        toast({ status: 'success', title: t`Webhook has been deleted.` })
      },
      onError: () => {
        toast({
          status: 'error',
          title: t`Could not delete webhook. Try again.`,
        })
      },
    })
  }
  return (
    <Tr>
      <Td>{props.title}</Td>
      <Td>{renderEvent(props.event)}</Td>
      <Td>{props.method}</Td>
      <Td>
        {props.enabled ? <Icon as={MdCloud} /> : <Icon as={MdCloudOff} />}
      </Td>
      <Td>
        <HStack>
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdEdit />}
            aria-label={t`Edit webhook`}
            onClick={() => {
              openWebhookModal({ webhookUid: props.uid })
            }}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdDelete />}
            aria-label={t`Delete webhook`}
            onClick={handleDeleteWebhook}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdCopyAll />}
            aria-label={t`Copy webhook to clipboard`}
            onClick={() => {
              copy(props.url)
              toast({
                status: 'success',
                title: t`Copied webhook url to clipboard.`,
              })
            }}
          />
        </HStack>
      </Td>
    </Tr>
  )
}
